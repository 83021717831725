<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 2,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <ITCGridComponent columnas="2" :data="[
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Consulta de Expedientes',
      url: '?uid=CUMV-VIEW-ExpedientesComponent',
    },
    {
      desc: null,
      icon: 'question_answer',
      target: '_self',
      title: 'Consultas y Solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent',
    },
  ]" />

  <!-- <DocumentListComponent
    resultados="5"
    :show-fecha="false"
    titulo="Guía de Trámites"
    :filtros="{ site: this.site }"
  /> -->

  <CardGridComponent columnas="3" :data="[
    {
      target: '_self',
      title: 'Licitaciones',
      url: 'https://licitaciones.sanjuan.gob.ar/',
    },
    {
      target: '_blank',
      title: 'Oferta Exportable',
      url: 'https://produccion.sanjuan.gob.ar/pdf/OfertaExportableESP-ENG.pdf',
    },
  {
    target: '_blank',
    title: 'RIM',
    url: 'https://relevamientoindustrial.sanjuan.gob.ar/',
  }
  ]" />

  <NewsComponent titulo="Noticias" />

  <!--   <BoxIconGridComponent titulo="Programas y Financiamiento" :data="[
    {
      desc: null,
      icon: 'ptn-i-produccion',
      target: '_blank',
      title: 'Dirección Instituto Tecnológico y Hortícola Semillero',
      url: 'https://contenido.sanjuan.gob.ar/index.php?n=0&option=com_k2&view=item&id=7008',
    },
    {
      desc: null,
      icon: 'ptn-i-vid',
      target: '_blank',
      title: 'Programa de Compras de Uva por parte del Gobierno de San Juan',
      url: 'https://contenido.sanjuan.gob.ar/index.php?n=0&option=com_k2&view=item&id=5110',
    },
    {
      desc: null,
      icon: 'ptn-i-hacienda-f',
      target: '_blank',
      title: 'Incentivos para el Desarrollo Ganadero',
      url: 'https://contenido.sanjuan.gob.ar/index.php?n=0&option=com_k2&view=item&id=4899',
    },
  ]" /> -->

  <FrecuentAccessComponent titulo="Secretarías y Organismos" subtitulo="Secretarías" :data="[
    {
      color: '#CD2006',
      title: 'Secretaría de Ciencia, Tecnología e Innovación',
      links: [
        {
          target: '_blank',
          title: '(0264) 430 5485',
          url: 'tel:02644305485',
        },
        {
          target: '_blank',
          title: '(0264) 430 5497',
          url: 'tel:02644305497',
        },
        {
          target: '_blank',
          title: 'gvoneuw@sanjuan.gov.ar',
          url: 'mailto:gvoneuw@sanjuan.gov.ar',
        },
        {
          target: '_blank',
          title: 'https://ciencia.sanjuan.gob.ar',
          url: 'https://ciencia.sanjuan.gob.ar',
        },

      ],
    },
    {
      color: '#CD2006',
      title: 'Secretaría de Agricultura, Ganadería y Agroindustria',
      links: [
        {
          target: '_self',
          title: '(0264) 430 5488',
          url: 'tel:2644305488',
        },
        {
          target: '_self',
          title: '(0264) 430 5498',
          url: 'tel:02644305498',
        },
        {
          target: '_self',
          title: 'mlmoreno@sanjuan.gov.ar',
          url: 'mailto:mlmoreno@sanjuan.gov.ar',
        },
      ],
    },
    {
      color: '#CD2006',
      title: 'Secretaría de Coordinación para el Desarrollo Económico',
      links: [
        {
          target: '_self',
          title: '(0264) 430 6420',
          url: 'tel:02644306420',
        },
        {
          target: '_self',
          title: '(0264) 430 6421',
          url: 'tel:02644306421',
        },
        {
          target: '_self',
          title: 'alfaciar@san juan.gov.ar',
          url: 'mailto:alfaciar@san juan.gov.ar',
        },
      ],
    },
    {
      color: '#CD2006',
      title: 'Secretaría de Industria, Comercio y Servicios',
      links: [
        {
          target: '_self',
          title: '(0264) 430 6437',
          url: 'tel:02644306437',
        },
        {
          target: '_self',
          title: '(0264) 430 6438',
          url: 'tel:02644306438',
        },
        {
          target: '_self',
          title: 'alejandro.martin@sanjuan.gov.ar',
          url: 'mailto:alejandro.martin@sanjuan.gov.ar',
        }
      ],
    }
  ]" />

  <FrecuentAccessComponent subtitulo="Sub-secretarías y Agencias" :data="[
    {
      color: '#FF8200',
      title: 'Sub Secretaría de Coordinación Administrativa',
      links: [
        {
          target: '_self',
          title: '(0264) 430 5484',
          url: 'tel:02644305484',
        },
        {
          target: '_self',
          title: 'mmdangelo@sanjuan.gov.ar',
          url: 'mailto:mmdangelo@sanjuan.gov.ar',
        }
      ],
    },
    {
      color: '#1D252D',
      title: 'Fiduciaria San Juan',
      links: [
        {
          target: '_self',
          title: '(0264) 421 1591',
          url: 'tel:02644211591',
        },
        {
          target: '_self',
          title: 'ggelusini@fiduciariasanjuan.com',
          url: 'mailto:ggelusini@fiduciariasanjuan.com',
        },
        {
          target: '_self',
          title: 'https://fiduciariasanjuan.com',
          url: 'https://fiduciariasanjuan.com'
        },
      ],
    },
    {
      color: '#1D252D',
      title: 'Agencia Calidad San Juan',
      links: [
        {
          target: '_self',
          title: '(0264) 427 4391',
          url: 'tel:02644274391',
        },
        {
          target: '_self',
          title: 'mrodriguez@calidadsj.com.ar',
          url: 'mailto:mrodriguez@calidadsj.com.ar',
        },
        {
          target: '_self',
          title: 'https://agenciacalidadsanjuan.com.ar',
          url: 'https://agenciacalidadsanjuan.com.ar',
        },
      ],
    },
    {
      color: '#1D252D',
      title: 'Agencia San Juan Desarrollo de Inversiones',
      links: [
        {
          target: '_self',
          title: '(0264) 429 6282',
          url: 'tel:02644296282',
        },
        {
          target: '_self',
          title: 'mpalisa@inversionessanjuan.gov.ar',
          url: 'mailto:mpalisa@inversionessanjuan.gov.ar',
        }
      ],
    }
  ]" />

  <FrecuentAccessComponent subtitulo="Dependencia Directa del Ministerio" :data="[
    {
      color: '#999999',
      title: 'Relaciones Institucionales y Comunicación',
      links: [
        {
          target: '_self',
          title: '(0264) 430 6435',
          url: 'tel:02644306435',
        },
        {
          target: '_blank',
          title: '(0264) 430 6430',
          url: 'tel:02644306430',
        },
        {
          target: '_self',
          title: 'fmariconda@sanjuan.gov.ar',
          url: 'mailto:fmariconda@sanjuan.gov.ar',
        },
      ],
    }
  ]" />

  <AutoriryComponent :showProtocolo="false" :showLinks="true" :data="[
    {
      title: 'Gustavo Emilio Fernandez',
      subtitle: 'Ministro de Producción, Trabajo e Innovación',
      imagen: require('@/assets/img/ministro-produccion.jpg'),
    },
  ]" />
</template>
