<template>
    <section class="sect">
        <div class="container">
            <h2 v-if="titulo" class="pb-5">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
            <div class="row d-flex">
                <h4 class="pb-2">{{ subtitulo }}</h4>
                <div class="mb-3 col-lg-3" v-for="(el, index) in  items " :key="index">
                    <h3 class="px-4 py-2 rounded-top fs-5" :style="{ backgroundColor: el.color }">
                        {{ el.title }}
                    </h3>
                    <ul class="list-unstyled px-4">
                        <li class="mb-2" v-for=" link  in  el.links " :key="link.title">
                            <a v-if="link.url" class="fac-a" v-bind:href="link.url"
                                v-bind:target="link.target ? link.target : '_self'">
                                <span v-if="link.url && link.url.includes('tel:')"
                                    class="material-symbols-outlined fac-span">call</span>
                                <span v-if="link.url && link.url.includes('mailto:')"
                                    class="material-symbols-outlined fac-span">mail</span>
                                <span v-if="link.url && link.url.includes('http')"
                                    class="material-symbols-outlined fac-span">public</span>
                                {{ link.title }}</a>
                            <span v-if="!link.url" class="fac-a">{{ link.title }}</span>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </section>
</template>


<script>
const axios = require('axios');
export default {
    name: 'FrecuentAccessComponent',
    props: {
        titulo: String,
        subtitulo: String,
        filtros: Object,
        data: Object,
    },
    data() {
        return {
            hash: "FAC",
            items: null,
            title: null,
            desc: null
        }
    },
    mounted() {
        if (this.data == null) {
            let params = {};
            if (this.filtros != null && this.filtros != "") {
                params = this.filtros;
            }
            params.tipo = this.hash;
            axios.post(process.env.VUE_APP_API_URL, params)
                .then(response => {
                    this.items = response.data ? response.data.items : null
                    this.title = response.data ? response.data.title : null
                    this.desc = response.data ? response.data.desc : null
                    localStorage.setItem(this.hash, JSON.stringify(response));
                })
                .catch(error => {
                    if (!error.response) {
                        console.log("Fuera de Linea");
                        if (localStorage.getItem(this.hash)) {
                            console.log("Cargando elementos de cache para " + this.hash);
                            var resp = JSON.parse(localStorage.getItem(this.hash));
                            this.items = resp.data ? resp.data.items : null
                            this.title = resp.data ? resp.data.title : null
                            this.desc = resp.data ? resp.data.desc : null
                        }
                    }
                })
        } else {
            this.items = this.data;
        }
    }
}
</script>

<style scoped>
h3 {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f1f1f1
}

.sect {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin-top: 20px;
}
</style>