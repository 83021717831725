<template>
  <div class="" style="min-height: 150px">
    <div
      style="text-align: justify; overflow-wrap: break-word"
      v-if="!customView && !isExpedientesComponent && !isFormComponent"
    >
      <div v-html="html"></div>
    </div>
    <div class="dinamic" v-if="customView">
      <component v-bind:is="customView"></component>
    </div>
    <FormComponent v-if="isFormComponent" />
    <ExpedientesComponent
      v-if="isExpedientesComponent"
      :filtros="{ site: 'dlc.siged.consulta.exp' }"
    />

    <div class="text-center pt-4">
      <p>
        <button v-on:click="back" class="btn btn-secondary text-uppercase">
          Volver
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExpedientesComponent from "./components/resources/ExpedientesComponent.vue";
import FormComponent from "./components/resources/FormComponent.vue";

export default {
  name: "InfoView",
  props: {
    uid: String,
    filtros: Object,
  },
  data() {
    return {
      html: null,
      customView: false,
      isExpedientesComponent: false,
      isFormComponent: false, // Agregada la propiedad isFormComponent
    };
  },
  methods: {
    back() {
      history.back();
    },
  },
  mounted() {
    if (this.uid === "CUMV-VIEW-FormComponent") {
      this.isFormComponent = true;
    } else if (this.uid === "CUMV-VIEW-ExpedientesComponent") {
      this.isExpedientesComponent = true;
    } else if (this.uid.includes("CUMV-"))
      switch (this.uid) {
        case "CUMV-": {
          this.html = ``;
          break;
        }
      }
    else {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.uid = this.uid;

      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.html = response.data ? response.data.html : null;
          localStorage.setItem(this.uid, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.uid)) {
              console.log("Cargando elementos de cache para " + this.uid);
              var resp = JSON.parse(localStorage.getItem(this.uid));
              this.html = resp.data ? resp.data.html : null;
            }
          }
        });
    }
  },
  components: { ExpedientesComponent, FormComponent },
};
</script>
